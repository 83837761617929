import { Chip, IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTheme } from '@material-ui/styles';
import React, { useState } from 'react';
import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  actionCol: {
    textAlign: 'right',
    display: 'block',
  },
}));

const StatusField = ({ record }) => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      style={{
        backgroundColor:
          record.id_customer !== null
            ? theme.palette.success.main
            : theme.palette.warning.main,
      }}
      label={record.id_customer !== null ? 'Customer' : 'Admin'}
    />
  );
};

const ActionField = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(10px) translateY(50px)',
          },
        }}>
        <MenuItem
          key="menu-edit"
          onClick={() => history.push(`/users/${record.id}`)}>
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};

const ListUser = props => {
  const classes = useStyles();
  return (
    <List {...props} filter={{ isnull__id_customer: true }}>
      <Datagrid>
        <TextField source="username" />
        <FunctionField
          label="Customer Name"
          render={record => record.customer_name}
        />
        <FunctionField
          label="User Types"
          render={record => <StatusField record={record} />}
        />
        <FunctionField
          label=""
          className={classes.actionCol}
          render={record => <ActionField record={record} />}
        />
      </Datagrid>
    </List>
  );
};

export default ListUser;
