import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import {
  FunctionField,
  ImageField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import noImage from '../../assets/no-image.jpg';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  fontCapitalize: {
    textTransform: 'capitalize',
  },
  contentText: {
    color: theme.palette.secondarytext.main,
    fontSize: '20px',
    lineHeight: '25px',
  },
  boxPadding: {
    paddingTop: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleProduct: {
    color: theme.palette.textcolor.main,
    fontSize: '14px',
    lineHeight: '24px',
    '& span': {
      fontSize: '12px',
      color: theme.palette.secondarytext.main,
    },
  },
  textBold: {
    color: theme.palette.textcolor.main,
    fontSize: '18px',
    lineHeight: '24px',
    fontweight: 700,
  },
  textItems: {
    color: '#858585',
    fontSize: '14px',
    lineHeight: '24px',
    fontweight: 700,
  },
  dashTopBorder: {
    borderTop: '2px dashed #858585',
  },
  titlecontentProduct: {
    fontSize: '18px',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    padding: ' 10px 8px',
  },
}));

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + (number < 0 ? '-' : '') + rp : rp;
};

const DetailPrice = ({ id, total }) => {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/detail/${id}`
  );

  const detailQty =
    data && (!loading || !error) ? data.details.map(d => d.qty) : null;

  const totalItem =
    detailQty !== null
      ? detailQty.reduce((a, b) => parseInt(a) + parseInt(b), 0)
      : 0;

  return (
    <Box>
      <Box display="flex" p={1} alignItems="center">
        <Box width="30%">
          <Typography className={classes.textItems}>Total Item</Typography>
        </Box>
        <Box width="70%" align="right">
          <Typography className={classes.textItems}>
            <NumberFormat number={total ? total : totalItem} />
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        className={classes.dashTopBorder}>
        <Box width="30%">
          <Typography className={classes.textBold}>Total</Typography>
        </Box>
        <Box width="70%" align="right">
          <Typography className={classes.titleProduct}>
            {data && (!loading || !error) ? (
              <NumberFormat number={data.gross_value} curency="Rp " />
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ItemsTransaction = ({ items }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex">
        <Box p={1} width="20%">
          <img
            src={items.product_photo ? items.product_photo : noImage}
            width="100%"
          />
        </Box>
        <Box p={1} width="40%">
          <Typography className={classes.titleProduct}>
            {items.product_name}{' '}
            <span>
              {items.note_detail ? ' [' + items.note_detail + ']' : null}
            </span>
          </Typography>
          <Box display="flex">
            <Box p={1} width="20%">
              <Typography
                className={classes.titleProduct}>{`X ${items.qty}`}</Typography>
            </Box>
            <Box p={1} width="80%" align="right">
              <Typography className={classes.textItems}>
                <NumberFormat number={items.total_price} curency="Rp " />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ListTransactionDetail = ({ id, total }) => {
  const classes = useStyles();

  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions_details/fn/list?sort=&page=&limit=&filter=[["transaction_id","_ilike","${id}"]]`
  );

  return (
    <Grid container>
      <Grid item sm={12}>
        <Typography className={classes.titlecontentProduct}>
          Detail Product
        </Typography>
      </Grid>
      <Grid item sm={8}>
        {!loading && !error ? (
          data.map((itm, idx) => <ItemsTransaction items={itm} />)
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </Grid>
      <Grid item sm={4}>
        <DetailPrice id={id} total={total} />
      </Grid>
    </Grid>
  );
};

const ShowTransaction = props => {
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/detail/${props.id}`
  );

  return (
    <Show {...props}>
      <Grid container>
        <Grid item sm={12}>
          <HeaderForm
            urlback="/point_transactions"
            titleform={
              data && data.type_id === 'PEMBELIAN'
                ? 'Detail Transaksi'
                : 'Detail Penukaran Point'
            }
          />
        </Grid>
        <Grid item sm={6}>
          <SimpleShowLayout>
            <ReferenceField
              label="Distributor / Reseller"
              source="customer_id"
              reference="customers"
              link={false}>
              <TextField source="name" />
            </ReferenceField>
            {data && data.type_id === 'PEMBELIAN' && data.shop_id ? (
              <ReferenceField
                label="Toko"
                source="shop_id"
                reference="shops"
                link={false}>
                <TextField source="name" />
              </ReferenceField>
            ) : null}

            {data && data.type_id === 'PEMBELIAN' && data.marketplace_id ? (
              <ReferenceField
                label="MarketPlace"
                source="marketplace_id"
                reference="master_marketplaces"
                link={false}>
                <TextField source="name" />
              </ReferenceField>
            ) : null}

            <ReferenceField
              label="Jenis Transaksi"
              source="type_id"
              reference="master_point_transaction_types"
              link={false}>
              <TextField source="name" />
            </ReferenceField>

            {data && data.type_id !== 'PEMBELIAN' ? (
              <FunctionField
                label="Tanggal Penukaran"
                render={record =>
                  `${record.tgl_trasaction} / ${record.time_trasaction}`
                }
              />
            ) : null}

            {data && data.type_id === 'PEMBELIAN' ? (
              <FunctionField
                label="Tanggal Penukaran"
                render={record =>
                  `${record.tgl_trasaction} / ${record.time_trasaction}`
                }
              />
            ) : null}

            {data && data.type_id === 'PEMBELIAN' ? (
              <FunctionField
                label="Gross Amount"
                render={record => (
                  <NumberFormat number={record.gross_value} curency="Rp " />
                )}
              />
            ) : null}

            {/* {data && data.type_id !== 'PEMBELIAN' ? (
              <FunctionField
                label="Current Point"
                render={record => `${record.current_point}`}
              />
            ) : null} */}

            {/* <FunctionField
              label="Point"
              render={record =>
                data
                  ? data && data.type_id !== 'PEMBELIAN'
                    ? '- ' + record.point
                    : '+ ' + record.point
                  : null
              }
            /> */}

            <TextField label="Catatan" source="note" />
          </SimpleShowLayout>
        </Grid>
        <Grid item sm={6}>
          <SimpleShowLayout>
            <ImageField
              source="photo"
              label={
                data && data.type_id === 'PEMBELIAN'
                  ? 'Foto Bukti Transaksi'
                  : 'Foto  Bukti Penukaran Point'
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item sm={12}>
          {data && data.type_id === 'PEMBELIAN' ? (
            <ListTransactionDetail id={data.id} total={data.total_qty} />
          ) : null}
        </Grid>
      </Grid>
    </Show>
  );
};

export default ShowTransaction;
