import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import jsonExport from 'jsonexport/dist';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  downloadCSV,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: 'contain',
    },
  },
});

const Filters = [
  <ReferenceInput
    source="product_id"
    reference="master_products"
    label="Nama Product"
    sort={{ field: 'name', order: 'ASC' }}
    filter={{ active: 1 }}
    alwaysOn
    fullWidth>
    <AutocompleteInput source="name" />
  </ReferenceInput>,
];

const exporter = (records, fetchRelatedRecords) => {
  // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
  let data = [];
  fetchRelatedRecords(records, 'product_id', 'master_products').then(
    product => {
      records.map(record =>
        data.push({
          product_name: product[record.product_id].name,
          level_id: record.level_id,
          price: record.price,
        })
      );
      fetchRelatedRecords(records, 'level_id', 'master_customer_levels').then(
        level => {
          const exportdata = data.map(r => ({
            product_name: r.product_name,
            level: level[r.level_id].name,
            price: r.price,
          }));
          jsonExport(
            exportdata,
            {
              headers: ['product_name', 'level', 'price'],
            },
            (err, csv) => {
              downloadCSV(csv, 'master_pricings');
            }
          );
        }
      );
    }
  );
};

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + rp : rp;
};

const ActionField = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(10px) translateY(50px)',
          },
        }}>
        <MenuItem
          key="menu-edit"
          onClick={() => history.push(`/master_pricings/${record.id}`)}>
          Edit
        </MenuItem>
        <MenuItem
          key="menu-detail"
          onClick={() => history.push(`/master_pricings/${record.id}/show`)}>
          Detail
        </MenuItem>
      </Menu>
    </>
  );
};

const ListPricings = props => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={Filters}
      sort={{ field: 'product_id', order: 'ASC' }}
      exporter={exporter}>
      <Datagrid>
        <ReferenceField
          label="Product"
          link={false}
          reference="master_products"
          source="product_id">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Level Customer"
          link={false}
          reference="master_customer_levels"
          source="level_id">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Price"
          render={record => (
            <NumberFormat
              number={record.price.replace('.00', '')}
              curency="Rp "
            />
          )}
        />
        <FunctionField
          label=""
          className={classes.actionCol}
          render={record => <ActionField record={record} />}
        />
      </Datagrid>
    </List>
  );
};

export default ListPricings;
