import {
  Box,
  Divider,
  Grid,
  MenuItem,
  styled as muiStyled,
  Toolbar,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { ViewList } from '@material-ui/icons';
import Layout, {
  getContent,
  getDrawerSidebar,
  getHeader,
  getSidebarContent,
  getSidebarTrigger,
  Root,
} from '@mui-treasury/layout';
// import { LoadingIndicator, Menu, Notification, UserMenu } from 'react-admin';
import {
  DashboardMenuItem,
  getResources,
  LoadingIndicator,
  MenuItemLink,
  Notification,
  useLogout,
} from 'react-admin';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import dashboardIcon from '../assets/icon/dashboard.svg';
import logOutIcon from '../assets/icon/log_out.svg';
import logo from '../assets/logo-sidebar.png';
import theme from './theme';

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarContent = getSidebarContent(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const Content = getContent(styled);

const scheme = Layout();

scheme.configureHeader(builder => {
  builder.registerConfig('xs', {
    position: 'sticky',
    clipped: true,
  });
});

scheme.configureEdgeSidebar(builder => {
  builder
    .create('sidebar', { anchor: 'left' })
    .registerTemporaryConfig('xs', {
      anchor: 'left',
      width: 256, // 'auto' is only valid for temporary variant
    })
    .registerPermanentConfig('lg', {
      width: 256, // px, (%, rem, em is compatible)
      collapsible: false,
    });
});

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  iconRoot: {
    textAlign: 'center',
  },
  menuSidebar: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 300,
    borderRadius: '5px',
    margin: '5px',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#C64447',
    },
    '&.RaMenuItemLink-active-19': {
      backgroundColor: '#fff',
      color: '#C64447',
    },
    '&:hover img': {
      filter:
        'invert(12%) sepia(20%) saturate(622933%) hue-rotate(400deg) brightness(105%) contrast(110%)',
    },
    '&.RaMenuItemLink-active-19 img': {
      filter:
        'invert(12%) sepia(20%) saturate(622933%) hue-rotate(400deg) brightness(105%) contrast(110%)',
    },
  },
});

const KeziaSidebar = styled(Box)`
  background-image: linear-gradient(
    to bottom,
    ${theme.palette.secondary.main} 50%,
    ${theme.palette.secondary.dark}
  );
  color: white;
`;

const KeziaHeader = muiStyled(Header)(() => ({
  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'transparent',
  },
}));

const KeziaStyle = createGlobalStyle`
  .EdgeHeaderOffset {
    background-color: ${theme.palette.secondary.main};
  }
`;

const MainLayout = ({ children, dashboard, logout, title }) => {
  const classes = useStyles();
  const resources = useSelector(getResources);
  const logoutLink = useLogout();
  const handleClick = () => logoutLink();

  return (
    <Root scheme={scheme} theme={theme}>
      {control => (
        <>
          <KeziaStyle />
          <KeziaHeader>
            <Toolbar>
              <SidebarTrigger sidebarId="sidebar" />
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                alignContent="center">
                <Grid item>
                  <img src={logo} width="25%" style={{ paddingTop: '50px' }} />
                </Grid>
                <Grid item>
                  <LoadingIndicator />
                </Grid>
              </Grid>
            </Toolbar>
          </KeziaHeader>
          <DrawerSidebar sidebarId="sidebar">
            <div
              style={{
                paddingTop: '45px',
                backgroundColor: '#C64447',
                paddingBottom: '20px',
              }}>
              <Divider style={{ backgroundColor: '#fff' }} />
            </div>
            <SidebarContent>
              <KeziaSidebar
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                alignContent="stretch"
                alignItems="stretch"
                height="100%"
                width="100%"
                px={1}>
                <DashboardMenuItem
                  primaryText="Dashboard"
                  className={classes.menuSidebar}
                  leftIcon={
                    <Icon className={classes.iconRoot}>
                      <img className={classes.imageIcon} src={dashboardIcon} />
                    </Icon>
                  }
                />
                {resources.map(resource => (
                  <MenuItemLink
                    className={classes.menuSidebar}
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                      (resource.options && resource.options.label) ||
                      resource.name
                    }
                    leftIcon={
                      resource.icon ? (
                        <Icon className={classes.iconRoot}>
                          <img
                            className={classes.imageIcon}
                            src={resource.icon}
                          />
                        </Icon>
                      ) : (
                        <ViewList style={{ color: '#fff' }} />
                      )
                    }
                  />
                ))}

                {/* <Menu logout={logout} hasDashboard={!!dashboard} />
                <UserMenu logout={logout} /> */}
                <MenuItem className={classes.menuSidebar} onClick={handleClick}>
                  <Icon
                    className={classes.iconRoot}
                    style={{ minWidth: '40px' }}>
                    <img className={classes.imageIcon} src={logOutIcon} />
                  </Icon>{' '}
                  Logout
                </MenuItem>
              </KeziaSidebar>
            </SidebarContent>
          </DrawerSidebar>
          <Content>
            <Box px={2}>{children}</Box>
          </Content>
          <Notification />
        </>
      )}
    </Root>
  );
};

export default MainLayout;
