import { CssBaseline } from '@material-ui/core';
import transactionIcon from 'assets/icon/jenis_transaksi.svg';
import customerLevelIcon from 'assets/icon/master_customer_level.svg';
import marketPlaceIcon from 'assets/icon/master_marketplace.svg';
import pricingIcon from 'assets/icon/master_pricing.svg';
import productIcon from 'assets/icon/master_product.svg';
import memberIcon from 'assets/icon/member_database.svg';
import memberTransactionIcon from 'assets/icon/member_transaction.svg';
import promoIcon from 'assets/icon/promo_event.svg';
import usersIcon from 'assets/icon/users.svg';
import axios from 'axios';
import { createBrowserHistory as createHistory } from 'history';
import Dashboard from 'layout/dashboard';
import MainLayout from 'layout/layout';
import LoginPage from 'layout/login';
import theme from 'layout/theme';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import indonesianMessages from 'ra-language-indonesian-new';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import SetAuthProvider, { ls } from './providers/auth';
import SetDataProvider from './providers/data';
import CreateCustomer from './resources/customers/create';
import EditCustomer from './resources/customers/edit';
import ListCustomer from './resources/customers/list';
import ShowCustomer from './resources/customers/show';
import CreateCustomerLevel from './resources/master_customer_levels/create';
import EditCustomerLevel from './resources/master_customer_levels/edit';
import ListCustomerLevels from './resources/master_customer_levels/list';
import ShowCustomerLevels from './resources/master_customer_levels/show';
import CreateMarketplace from './resources/master_marketplaces/create';
import EditMartketPlace from './resources/master_marketplaces/edit';
import ListMarketplace from './resources/master_marketplaces/list';
import CreateType from './resources/master_point_transaction_types/create';
import EditPymentType from './resources/master_point_transaction_types/edit';
import ListType from './resources/master_point_transaction_types/list';
import ShowPymentType from './resources/master_point_transaction_types/show';
import CreatePricings from './resources/master_pricings/create';
import EditPricings from './resources/master_pricings/edit';
import ListPricings from './resources/master_pricings/list';
import ShowPricings from './resources/master_pricings/show';
import CreateProduct from './resources/master_products/create';
import EditProduct from './resources/master_products/edit';
import ListProducts from './resources/master_products/list';
import ShowProduct from './resources/master_products/show';
import CreateNews from './resources/news/create';
import EditNews from './resources/news/edit';
import ListNews from './resources/news/list';
import showNews from './resources/news/show';
import CreateTransaction from './resources/point_transactions/create';
import EditTransaction from './resources/point_transactions/edit';
import ListTransaction from './resources/point_transactions/list';
import ShowTransaction from './resources/point_transactions/show';
import CreateUser from './resources/users/create';
import EditUser from './resources/users/edit';
import ListUser from './resources/users/list';

const history = createHistory({ basename: process.env.REACT_APP_BASE_PATH });

console.log(history);

const messages = {
  id: indonesianMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'id');

axios.interceptors.request.use(config => {
  const session = ls.get('session');

  if (session.token) {
    config.headers.authorization = `Bearer ${session.token}`;
  }

  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    console.error('AXIOS', error.response);
    return Promise.reject({
      message: error.response.data?.error || error.message,
      status: error.status,
      axios: error,
    });
  }
);

const dataProvider = SetDataProvider(process.env.REACT_APP_API_URL);
const authProvider = SetAuthProvider(process.env.REACT_APP_API_URL);

const App = () => {
  return (
    <>
      <CssBaseline />
      <Admin
        theme={theme}
        layout={MainLayout}
        loginPage={LoginPage}
        dashboard={Dashboard}
        history={history}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}>
        <Resource
          name="customers"
          list={ListCustomer}
          create={CreateCustomer}
          edit={EditCustomer}
          show={ShowCustomer}
          icon={memberIcon}
          options={{ label: 'Member Database' }}
        />
        {/* <Resource
          name="shops"
          list={ListShop}
          create={CreateShop}
          edit={EditShop}
          show={showShop}
          icon={shopIcon}
          options={{ label: 'Shops' }}
        /> */}
        <Resource
          name="news"
          list={ListNews}
          create={CreateNews}
          edit={EditNews}
          show={showNews}
          icon={promoIcon}
          options={{ label: 'Promo Event' }}
        />
        <Resource
          name="point_transactions"
          list={ListTransaction}
          create={CreateTransaction}
          edit={EditTransaction}
          show={ShowTransaction}
          icon={memberTransactionIcon}
          options={{ label: 'Member Transactions' }}
        />
        <Resource
          name="users"
          list={ListUser}
          create={CreateUser}
          edit={EditUser}
          icon={usersIcon}
          options={{ label: 'Users' }}
        />
        <Resource
          name="master_marketplaces"
          list={ListMarketplace}
          create={CreateMarketplace}
          edit={EditMartketPlace}
          icon={marketPlaceIcon}
          options={{ label: 'Master Marketplace' }}
        />
        <Resource
          name="master_products"
          list={ListProducts}
          create={CreateProduct}
          edit={EditProduct}
          show={ShowProduct}
          icon={productIcon}
          options={{ label: 'Master Products' }}
        />
        <Resource
          name="master_customer_levels"
          list={ListCustomerLevels}
          create={CreateCustomerLevel}
          edit={EditCustomerLevel}
          show={ShowCustomerLevels}
          icon={customerLevelIcon}
          options={{ label: 'Master Customer Level' }}
        />
        <Resource
          name="master_pricings"
          list={ListPricings}
          create={CreatePricings}
          edit={EditPricings}
          show={ShowPricings}
          icon={pricingIcon}
          options={{ label: 'Master Prices' }}
        />
        <Resource
          name="master_point_transaction_types"
          list={ListType}
          create={CreateType}
          edit={EditPymentType}
          show={ShowPymentType}
          icon={transactionIcon}
          options={{ label: 'Transaction Type' }}
        />
      </Admin>
    </>
  );
};

export default App;
