import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  ImageField,
  ImageInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
  boxImg: {
    padding: theme.spacing(0, 4, 4, 4),
  },
}));

const CreateProduct = props => {
  const classes = useStyles();

  const [fieldDisbled, setFieldDisbled] = useState(false);

  const selectPackage = e => {
    setFieldDisbled(e === '1' ? true : false);
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm
              urlback="/master_products"
              titleform="Form Tambah Produk Baru"
            />
          </Grid>
          <Grid item sm={6}>
            <TextInput source="code" label="Kode" fullWidth />
            <TextInput source="name" fullWidth />
            <TextInput
              source="description"
              label="Deskripsi Produk"
              multiline
              fullWidth
            />
            <NumberInput
              source="official_price"
              label="Harga Official"
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <Box className={classes.boxImg}>
              <ImageInput source="photo" label="Foto" accept="image/*">
                <ImageField source="src" />
              </ImageInput>

              <RadioButtonGroupInput
                source="is_reward"
                choices={[
                  { id: '0', name: 'Tidak' },
                  { id: '1', name: 'Ya' },
                ]}
                label="Apakah Produk Reward"
              />

              <RadioButtonGroupInput
                source="active"
                choices={[
                  { id: '0', name: 'Tidak' },
                  { id: '1', name: 'Ya' },
                ]}
                label="Produk Aktif"
              />
            </Box>
          </Grid>
          <Grid item sm={12}>
            <RadioButtonGroupInput
              source="is_package"
              choices={[
                { id: '0', name: 'Tidak' },
                { id: '1', name: 'Ya' },
              ]}
              label="Apakah produk Paket? "
              onChange={selectPackage}
            />

            <ArrayInput source="package_items" label="Item Produk Paket">
              <SimpleFormIterator>
                <ReferenceInput
                  source="product_id"
                  reference="master_products"
                  label="Produk"
                  filter={{ is_package: [0], is_reward: [0] }}>
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="qty" label="Jumlah Barang" />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateProduct;
