import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTheme } from '@material-ui/styles';
import axios from 'axios';
import jsonExport from 'jsonexport/dist';
import React, { useState } from 'react';
import {
  Datagrid,
  downloadCSV,
  FunctionField,
  ImageField,
  List,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: 'contain',
    },
  },
});

const Filters = [
  <TextInput label="Search" source="name" alwaysOn />,
  <TextInput source="name" />,
];

const StatusField = ({ record }) => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      style={{
        backgroundColor:
          record.active === '1'
            ? theme.palette.success.main
            : theme.palette.danger.main,
      }}
      label={record.active === '1' ? 'Active' : 'Inactive'}
    />
  );
};

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + rp : rp;
};

const ActionField = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatus = () => {
    const newStatus = record.active === '1' ? '0' : '1';
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/master_products/fn/edit/${record.id}`,
        { active: newStatus, edit_status: true }
      )
      .then(response => {
        const dt = response.data;

        if (dt) {
          refresh();
          unselectAll('shops');
          notify(
            <Typography style={{ color: '#fff' }}>
              Status Product updated
            </Typography>,
            'success'
          );
        } else {
          notify(
            <Typography style={{ color: '#fff' }}>
              Status Product not updated
            </Typography>,
            'danger'
          );
        }
      });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(10px) translateY(50px)',
          },
        }}>
        <MenuItem
          key="menu-edit"
          onClick={() => history.push(`/master_products/${record.id}`)}>
          Edit
        </MenuItem>
        <MenuItem
          key="menu-detail"
          onClick={() => history.push(`/master_products/${record.id}/show`)}>
          Detail
        </MenuItem>
        <MenuItem key="menu-activated" onClick={changeStatus}>
          {record.active === '1' ? 'Inactived' : 'Active'}
        </MenuItem>
      </Menu>
    </>
  );
};

const exporter = posts => {
  const postsForExport = posts.map(function (a) {
    return {
      product_code: a.code,
      product_name: a.name,
      official_price: a.official_price.replace('.00', ''),
      description: a.description,
      type_product:
        a.is_package === '1'
          ? 'Produk Paket'
          : a.is_reward === '1'
          ? 'Produk Reward'
          : 'Produk Satuan',
      status: a.active === '1' ? 'Active' : 'Inactive',
    };
  });

  jsonExport(
    postsForExport,
    {
      headers: [
        'product_code',
        'product_name',
        'official_price',
        'description',
        'type_product',
        'status',
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'master_products'); // download as 'posts.csv` file
    }
  );
};

const ListProducts = props => {
  const location = useLocation();
  const classes = useStyles();

  const filterItem =
    location.state && location.state.selective === true
      ? { in__id: location.state.products }
      : null;

  return (
    <List {...props} filters={Filters} filter={filterItem} exporter={exporter}>
      <Datagrid>
        <ImageField className={classes.imgContainer} source="photo" />
        <TextField source="name" />
        <TextField source="code" label="Product Code" />
        <FunctionField
          label="Official Price"
          render={record => (
            <NumberFormat
              number={record.official_price.replace('.00', '')}
              curency="Rp "
            />
          )}
        />
        <TextField source="description" />
        <FunctionField
          label="Type Product"
          render={record =>
            record.is_package === '1'
              ? 'Produk Paket'
              : record.is_reward === '1'
              ? 'Produk Reward'
              : 'Produk Satuan'
          }
        />
        <FunctionField
          label="Status"
          render={record => <StatusField record={record} />}
        />
        <FunctionField
          label=""
          className={classes.actionCol}
          render={record => <ActionField record={record} />}
        />
      </Datagrid>
    </List>
  );
};

export default ListProducts;
