import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Notification, useLogin, useNotify } from 'react-admin';
import bgLogin from '../assets/banner.jpg';
import logo from '../assets/logo.png';

const useStyles = makeStyles(theme => ({
  leftContent: {
    height: '100vh',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightContent: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '100% auto',
  },
  loginHelper: {
    width: '80%',
  },

  formWrapper: {
    padding: '20px 10px',
    minHeight: '200px',
  },
  formContent: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },

  loginTitle: {
    color: '#2B3674',
    fontWeight: 700,
    fontSize: '34px',
    letterSpacing: '-2%',
  },

  loginsubTitle: {
    color: '#2B3674',
    fontWeight: 500,
    fontSize: '18px',
    letterSpacing: '-2%',
    marginBottom: theme.spacing(3),
  },

  buttonLogin: {
    padding: '15px 40px',
    float: 'right',
    marginTop: '20px',
  },

  customeField: {
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: '#FFF',
    '& > div': {
      borderRadius: '5px',
      border: '1px solid #C7C9D9',
    },
    '& > div:hover fieldset': {
      borderColor: '#16A085 !important',
    },
    '& input': {
      color: '#34495E',
    },
    '& label': {
      color: '#C7C9D9',
    },
  },

  notifBg: {
    backgroundColor: '#fc5a5d',
  },
}));

const FormLogin = () => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const login = useLogin();
  const notify = useNotify();
  const submit = e => {
    e.preventDefault();
    login({ username, password }).catch(() =>
      notify('Invalid username or password')
    );
  };

  return (
    <Box className={classes.formContent}>
      <Typography className={classes.loginTitle} variant="h2">
        Welcome Back!
      </Typography>
      <Typography className={classes.loginsubTitle} variant="subtitle1">
        Please Log in to your account.
      </Typography>
      <form onSubmit={submit}>
        <TextField
          className={classes.customeField}
          onChange={e => setUsername(e.target.value)}
          id="username"
          name="username"
          label="Username"
          type="text"
          variant="outlined"
        />
        <TextField
          className={classes.customeField}
          onChange={e => setPassword(e.target.value)}
          id="password"
          name="password"
          label="Password"
          type="password"
          variant="outlined"
        />
        <Button
          className={classes.buttonLogin}
          type="submit"
          variant="contained"
          color="primary">
          Log in
        </Button>
      </form>
    </Box>
  );
};

const LoginPage = ({ theme }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container className={classes.customeContiner}>
        <Grid item sm={6}>
          <Box className={classes.leftContent}>
            <Box className={classes.loginHelper}>
              <Box className={classes.formWrapper}>
                <img src={logo} width="20%" />
                <FormLogin />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box
            className={classes.rightContent}
            style={{ backgroundImage: `url(${bgLogin})` }}></Box>
        </Grid>
      </Grid>
      <Notification className={classes.notifBg} />
    </MuiThemeProvider>
  );
};

export default LoginPage;
