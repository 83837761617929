import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTheme } from '@material-ui/styles';
import axios from 'axios';
import jsonExport from 'jsonexport/dist';
import React, { useState } from 'react';
import {
  Datagrid,
  downloadCSV,
  FunctionField,
  List,
  TextField,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  actionCol: {
    textAlign: 'right',
    display: 'block',
  },
}));

const StatusField = ({ record }) => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      style={{
        backgroundColor:
          record.active === '1'
            ? theme.palette.success.main
            : theme.palette.danger.main,
      }}
      label={record.active === '1' ? 'Active' : 'Inactive'}
    />
  );
};

const ActionField = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatus = () => {
    const newStatus = record.active === '1' ? '0' : '1';
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/master_marketplaces/fn/edit/${record.id}`,
        { active: newStatus, edit_status: true }
      )
      .then(response => {
        const dt = response.data;

        if (dt) {
          refresh();
          unselectAll('shops');
          notify(
            <Typography style={{ color: '#fff' }}>
              Status Master Data Marketplaces updated
            </Typography>,
            'success'
          );
        } else {
          notify(
            <Typography style={{ color: '#fff' }}>
              Status Master Data Marketplaces not updated
            </Typography>,
            'danger'
          );
        }
      });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(10px) translateY(50px)',
          },
        }}>
        <MenuItem
          key="menu-edit"
          onClick={() => history.push(`/master_marketplaces/${record.id}`)}>
          Edit
        </MenuItem>
        <MenuItem
          key="menu-detail"
          onClick={() =>
            history.push(`/master_marketplaces/${record.id}/show`)
          }>
          Detail
        </MenuItem>
        <MenuItem key="menu-activated" onClick={changeStatus}>
          {record.active === '1' ? 'Inactived' : 'Active'}
        </MenuItem>
      </Menu>
    </>
  );
};

const exporter = posts => {
  const postsForExport = posts.map(function (a) {
    return {
      name: a.name,
      status: a.active === '1' ? 'Active' : 'Inactive',
    };
  });

  jsonExport(
    postsForExport,
    {
      headers: ['name', 'status'], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'master_marketplaces'); // download as 'posts.csv` file
    }
  );
};

const ListMarketplace = props => {
  const classes = useStyles();
  return (
    <List {...props} exporter={exporter}>
      <Datagrid>
        <TextField source="name" />
        <FunctionField
          label="Status"
          render={record => <StatusField record={record} />}
        />
        <FunctionField
          label=""
          className={classes.actionCol}
          render={record => <ActionField record={record} />}
        />
      </Datagrid>
    </List>
  );
};

export default ListMarketplace;
