import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  titleForm: {
    color: theme.palette.textcolor.main,
    fontWeight: 300,
    fontSize: '20px',
  },
  headerForm: {
    marginBottom: theme.spacing(3),
  },
}));

const HeaderForm = props => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.headerForm}>
      <Box display="flex" alignItems="center">
        <Box>
          <IconButton
            aria-label="back"
            onClick={() => history.push(props.urlback)}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box p={1} flexGrow={1}>
          <Typography variant="h3" className={classes.titleForm}>
            {props.titleform}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderForm;
