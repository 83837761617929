import { Chip, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import {
  FunctionField,
  ImageField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const StatusField = ({ record }) => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      style={{
        backgroundColor:
          record.active === '1'
            ? theme.palette.success.main
            : theme.palette.danger.main,
      }}
      label={record.active === '1' ? 'Active' : 'Inactive'}
    />
  );
};

const showNews = props => {
  return (
    <Show {...props}>
      <Grid container>
        <Grid item sm={12}>
          <HeaderForm urlback="/news" titleform="Detail News" />
        </Grid>
        <Grid item sm={6}>
          <SimpleShowLayout>
            <ImageField source="banner" />
            <TextField source="title" />
            <RichTextField source="context" />
            <FunctionField
              label="Status"
              render={record => <StatusField record={record} />}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item sm={6}></Grid>
      </Grid>
    </Show>
  );
};

export default showNews;
