import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutocompleteInput,
  DateInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
  width50: {
    width: '50%',
    paddingRight: theme.spacing(2),
  },
}));

const EditCustomer = props => {
  const classes = useStyles();

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm urlback="/customers" titleform="Edit Data Member" />
          </Grid>
          <Grid item sm={6}>
            <TextInput source="code" label="Kode" fullWidth />
            <TextInput source="nik" label="No. KTP" fullWidth />
            <TextInput source="name" label="Nama Lengkap" fullWidth />
            <TextInput source="address" label="Alamat" multiline fullWidth />
            <DateInput
              source="birthdate"
              label="Tgl. Lahir"
              className={classes.width50}
            />
            <SelectInput
              source="gender"
              label="Jenis Kelamin"
              choices={[
                { id: 'MALE', name: 'Pria' },
                { id: 'FEMALE', name: 'Wanita' },
              ]}
              className={classes.width50}
            />
            <DateInput source="join_date" label="Tgl. Bergabung" fullWidth />
            <TextInput source="area" label="Area Bisnis" fullWidth />
            <TextInput source="phone" label="No. Telp / WA" fullWidth />
            <TextInput source="email" label="Email" type="email" fullWidth />
            <TextInput source="wa_grup" label="Wa Group" fullWidth />
            <TextInput source="instagram" label="Instagram" fullWidth />
            <TextInput source="facebook" label="Facebook" fullWidth />
            <ReferenceInput
              source="level_id"
              reference="master_customer_levels"
              label="Customer Level"
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ active: 1 }}>
              <AutocompleteInput source="name" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item sm={6}></Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditCustomer;
