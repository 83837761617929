import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Create, PasswordInput, SimpleForm, TextInput } from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
}));

const CreateUser = props => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm urlback="/users" titleform="Menambahkan Users Baru" />
          </Grid>
          <Grid item sm={6}>
            <TextInput source="username" fullWidth />
            <PasswordInput source="password" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateUser;
