import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, PasswordInput, SimpleForm, TextInput } from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
}));

const EditUser = props => {
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm urlback="/users" titleform="Edit Data User" />
          </Grid>
          <Grid item sm={6}>
            <TextInput source="username" fullWidth />
            <PasswordInput source="new_pass" fullWidth />
            <PasswordInput
              source="repassword"
              label="Confirm Password"
              fullWidth
            />
          </Grid>
          <Grid item sm={6}></Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditUser;
