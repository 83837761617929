import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
}));

const EditCustomerLevel = props => {
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm
              urlback="/master_customer_levels"
              titleform="Form Edit Data Master Customer Level"
            />
          </Grid>
          <Grid item sm={6}>
            <TextInput source="name" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditCustomerLevel;
