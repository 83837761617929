import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';
import useAxios from 'axios-hooks';
import {
  FunctionField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  imgContainer: {
    '& img': {
      height: 'auto',
      width: '100%',
      objectFit: 'contain',
    },
  },
  titleForm: {
    color: theme.palette.textcolor.main,
    fontWeight: 300,
    fontSize: '20px',
    marginBottom: theme.spacing(3),
  },
  contentProduct: {
    borderTop: '1px dashed #ccc',
    paddingTop: theme.spacing(2),
  },
  titleProduct: {
    color: theme.palette.textcolor.main,
    fontSize: '14px',
    lineHeight: '24px',
  },
  textItems: {
    color: '#858585',
    fontSize: '14px',
    lineHeight: '24px',
    fontweight: 700,
  },
}));

const StatusField = ({ record }) => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      style={{
        backgroundColor:
          record.active === '1'
            ? theme.palette.success.main
            : theme.palette.danger.main,
      }}
      label={record.active === '1' ? 'Active' : 'Inactive'}
    />
  );
};

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + rp : rp;
};

const ItemProduct = ({ items }) => {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/master_products/fn/detail/${items.product_id}`
  );

  return (
    <Grid container>
      <Grid item sm={5}>
        <Box display="flex">
          <Box p={1} width="20%">
            {data && !loading && !error ? (
              <img src={data.photo} width="100%" />
            ) : (
              <Skeleton />
            )}
          </Box>
          <Box p={1} width="40%">
            <Typography className={classes.titleProduct}>
              {data && !loading && !error ? data.name : <Skeleton />}
            </Typography>
            <Box display="flex">
              <Box p={1} width="20%">
                <Typography
                  className={
                    classes.titleProduct
                  }>{`X ${items.qty}`}</Typography>
              </Box>
              <Box p={1} width="80%" align="right">
                <Typography className={classes.textItems}>
                  <NumberFormat
                    number={
                      data && !loading && !error ? (
                        data.official_price.replace('.00', '')
                      ) : (
                        <Skeleton />
                      )
                    }
                    curency="Rp "
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={3}></Grid>
      <Grid item sm={4}></Grid>
    </Grid>
  );
};

const DetailPacket = ({ record }) => {
  const classes = useStyles();

  const products = record.package;

  return (
    <Box p={1} className={classes.contentProduct}>
      <Typography variant="h4" className={classes.titleForm}>
        Detail Paket
      </Typography>
      {products ? (
        products.map((itm, idx) => <ItemProduct items={itm} />)
      ) : (
        <Skeleton />
      )}
    </Box>
  );
};

const ShowProduct = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <Grid container>
        <Grid item sm={12}>
          <HeaderForm urlback="/master_products" titleform="Detail Products" />
        </Grid>
        <Grid item sm={6}>
          <SimpleShowLayout>
            <TextField source="code" label="Kode" />
            <TextField source="name" label="Nama Produk" />
            <TextField source="description" label="Deskripsi Produk" />
            <FunctionField
              label="Official Price"
              render={record => (
                <NumberFormat
                  number={record.official_price.replace('.00', '')}
                  curency="Rp "
                />
              )}
            />
            <FunctionField
              label="Tipe Produk"
              render={record =>
                record.is_package === '1'
                  ? 'Produk Paket'
                  : record.is_reward === '1'
                  ? 'Produk Reward'
                  : 'Produk Satuan'
              }
            />
            <FunctionField
              label="Status"
              render={record => <StatusField record={record} />}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item sm={6}>
          <ImageField className={classes.imgContainer} source="photo" />
        </Grid>
        <Grid item sm={12}>
          <FunctionField
            label=""
            render={record =>
              record.is_package === '1' ? (
                <DetailPacket record={record} />
              ) : null
            }
          />
        </Grid>
      </Grid>
    </Show>
  );
};

export default ShowProduct;
