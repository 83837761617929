import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
}));

const CreatePricings = props => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm
              urlback="/master_pricings"
              titleform="Form Tambah Data Master Harga Produk"
            />
          </Grid>
          <Grid item sm={6}>
            <ReferenceInput
              source="product_id"
              reference="master_products"
              label="Product"
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ active: 1 }}
              fullWidth>
              <AutocompleteInput source="name" />
            </ReferenceInput>
            <ReferenceInput
              source="level_id"
              reference="master_customer_levels"
              label="Customer Level ID"
              sort={{ field: 'name', order: 'ASC' }}
              fullWidth>
              <AutocompleteInput source="name" />
            </ReferenceInput>
            <NumberInput source="price" label="Price" min="0" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreatePricings;
