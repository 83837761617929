import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import { useGetIdentity } from 'react-admin';
import { useHistory } from 'react-router';
import addingPointIcon from '../assets/addingPoint.png';
import graphIcon from '../assets/graph.png';
import memberPurchasing from '../assets/memberPurchasing.png';
import membersIcon from '../assets/members.png';
import redemPointIcon from '../assets/redemPoint.png';

const useStyles = makeStyles(theme => ({
  titleHeader: {
    fontWeight: 600,
    fontSize: '34px',
    lineHeight: '42px',
    color: '#2B3674',
  },
  subTitleHeader: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#707EAE',
  },
  contentWrapper: {
    marginTop: theme.spacing(5),
    color: '#1B2559',
  },
  rowColoumn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  boxInfoWrapper: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '2px 2px 3px rgba(0,0,0,0.3)',
    padding: '20px 10px',
    marginRight: theme.spacing(2),
  },
  boxInfo: {
    paddingLeft: '10px',
  },
  pruchacesBox: {
    color: '#fff',
    background: 'linear-gradient(280.58deg, #16A085 19.93%, #43DCBE 99.93%)',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
}));

const HeaderDasboard = () => {
  const { loading, error, identity } = useGetIdentity();

  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.subTitleHeader} variant="body1">
        Hi {!loading && !error ? identity.username : <Skeleton />},
      </Typography>
      <Typography className={classes.titleHeader} variant="h3">
        Welcome to Dashboard Kezia!
      </Typography>
    </Box>
  );
};

const NumberFormat = ({ number }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return rp;
};

const Dashboard = ({ props }) => {
  const classes = useStyles();
  const history = useHistory();

  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/dashboard/fn/list`
  );

  return (
    <Box>
      <HeaderDasboard />
      <Box className={classes.contentWrapper}>
        <Box className={classes.boxItems}>
          <Typography>
            Statistik Periode{' '}
            {data && !loading && !error ? data.ranges_date : <Skeleton />}
          </Typography>
          <Grid container className={classes.rowColoumn}>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                className={`${classes.boxInfoWrapper} ${classes.pointerCursor}`}
                onClick={() => history.push(`/customers/`, null)}>
                <Box width="20%">
                  <img src={membersIcon} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>Total Members</Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      <NumberFormat number={data.total_member} />
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                className={`${classes.boxInfoWrapper} ${classes.pointerCursor}`}
                onClick={() => history.push(`/customers/`, { active: true })}>
                <Box width="20%">
                  <img src={graphIcon} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>Total Member Active</Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      <NumberFormat number={data.total_member_active} />
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                className={classes.boxInfoWrapper}
                className={`${classes.boxInfoWrapper} ${classes.pointerCursor}`}
                onClick={() =>
                  history.push(`/customers/`, { join_date: true })
                }>
                <Box width="20%">
                  <img src={membersIcon} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>New Member</Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      <NumberFormat number={data.total_new_member} />
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                className={`${classes.boxInfoWrapper} ${classes.pointerCursor}`}
                onClick={() =>
                  history.push(`/master_products/`, {
                    selective: true,
                    products: data.product_id_selected,
                  })
                }>
                <Box width="20%">
                  <img src={addingPointIcon} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>Product Sold this Month </Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      <NumberFormat number={data.total_adding_point} />
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container className={classes.rowColoumn}>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                onClick={() =>
                  history.push(`/point_transactions/`, {
                    transaction: 'PEMBELIAN',
                  })
                }
                className={`${classes.boxInfoWrapper} ${classes.pruchacesBox} ${classes.pointerCursor}`}>
                <Box width="20%">
                  <img src={memberPurchasing} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>Member Purchasing</Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      <NumberFormat number={data.total_member_purchase} />
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                className={`${classes.boxInfoWrapper} ${classes.pointerCursor}`}
                onClick={() =>
                  history.push(`/point_transactions/`, {
                    transaction: 'TUKAR_POIN',
                  })
                }>
                <Box width="20%">
                  <img src={redemPointIcon} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>Point Redemption</Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      <NumberFormat number={data.total_redeem_point} />
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                className={`${classes.boxInfoWrapper} ${classes.pointerCursor}`}
                onClick={() =>
                  history.push(`/customers/${data.most_member_id}/show`)
                }>
                <Box width="20%">
                  <img src={graphIcon} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>Member of the Month</Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      data.most_member
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box
                display="flex"
                flexWrap="nowrap"
                p={1}
                className={`${classes.boxInfoWrapper}`}>
                <Box width="20%">
                  <img src={graphIcon} width="90%" />
                </Box>
                <Box flexShrink={1} className={classes.boxInfo}>
                  <Typography>Product of the Month</Typography>
                  <Typography>
                    {data !== undefined && (!loading || !error) ? (
                      data.most_product
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
