import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  DateInput,
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
  width50: {
    width: '50%',
    paddingRight: theme.spacing(2),
  },
  boxDetail: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  boxPrice: {
    backgroundColor: '#DEDEDE',
    padding: '5px 20px 0 15px',
    marginRight: theme.spacing(3),
    borderRadius: '5px 5px 0 0',
    borderBottom: '1px solid #666',
    marginTop: theme.spacing(1),
    height: '48px',
  },
  priceCustomer: {
    paddingRight: '20px',
    paddingTop: '8px',
    '& input': {
      paddingTop: '24px',
      paddingBottom: '5px',
    },
  },
  titleHarga: {
    fontSize: '12px',
    color: '#727272',
  },
  boxTotal: {
    backgroundColor: '#DEDEDE',
    padding: '5px 20px 0 15px',
    borderRadius: '5px 5px 0 0',
    borderBottom: '1px solid #666',
  },
  inputQty: {
    width: '100%',
  },
}));

const useIteratorStyle = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    '& .ra-input': {
      marginRight: theme.spacing(2),
    },
    '& .ra-input:nth-child(2)': {
      width: '10%',
    },
    '& .ra-input:nth-child(3)': {
      width: '25%',
    },
  },
}));

const PreviewImage = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const NumberFormat = ({ number, curency, totalHarga }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + (number < 0 ? '-' : '') + rp : rp;
};

//getPrice
const Price = ({ scopedata, idCustomer, getPrice }) => {
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/detailpriceproduct/${idCustomer}__${scopedata.product_id}`
  );

  useEffect(() => {
    if (data && data.price_int && idCustomer) {
      getPrice(data);
    }
  }, [data, scopedata, idCustomer]);

  return <></>;
};

const EditTransaction = props => {
  const classes = useStyles();
  const iteratorClasses = useIteratorStyle();

  const [price, setPrice] = useState(0);
  const [grandtotal, SetGrandTotal] = useState(0);
  const [point, setPoint] = useState(0);
  const [changeItems, setChangeItems] = useState(false);

  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/detail/${props.id}`
  );

  useEffect(() => {
    if (data) {
      setPoint(data.point);
      SetGrandTotal(data.gross_value);
    }
  }, [data]);

  const Points = dt => {
    let point = 0;
    let subTotal = 0;
    if (dt.length > 0) {
      dt.map(function (obj, i) {
        if (obj !== undefined) {
          if (obj.package === true && obj.total > 0) {
            point = parseInt(point) + 1 * parseInt(obj.qty);
          } else {
            subTotal = parseInt(subTotal) + parseInt(obj.total);
          }
        }
      });
    }

    return (point = parseInt(point) + Math.floor(parseInt(subTotal) / 250000));
  };
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm
              urlback="/point_transactions"
              titleform="Form Edit Transaksi"
            />
          </Grid>
          <Grid item sm={6}>
            {data && data.type_id !== 'PEMBELIAN' ? (
              <DateInput source="timestamp" label="Date" fullWidth />
            ) : (
              <DateInput
                source="timestamp"
                label="Transaction Date"
                initialValue={moment().format('MM/DD/YYYY')}
                disabled
                fullWidth
              />
            )}

            <ReferenceInput
              source="type_id"
              reference="master_point_transaction_types"
              label="Jenis Transaksi"
              disabled={data && data.type_id !== 'PEMBELIAN' ? true : false}>
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>

            <ReferenceInput
              source="customer_id"
              reference="customers"
              label="Distributor / Reseller"
              disabled={data && data.type_id !== 'PEMBELIAN' ? true : false}>
              <AutocompleteInput optionText="info_label" fullWidth />
            </ReferenceInput>
            {data && data.type_id === 'PEMBELIAN' ? (
              <ReferenceInput
                fullWidth
                source="marketplace_id"
                reference="master_marketplaces"
                label="Marketplace">
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            ) : null}

            {/* {data && data.type_id === 'PEMBELIAN' ? (
              <>
              <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
                <TextInput source="customer_name" label="Nama Customer" style={{ width: '50%', marginRight: 10 }} />
                <TextInput source="telephone" label="Nama Telephone" style={{ width: '50%'}} />
              </div>
              <TextInput source="address" label="Alamat" fullWidth />
              </>
            ): null} */}

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              {/* {data && data.type_id === 'PEMBELIAN' ? (
                <NumberInput
                  source="gross_value"
                  label="Gross Amount"
                  min="0"
                  style={{ width: '50%', marginRight: 40 }}
                />
              ) : null} */}
              {data && data.type_id !== 'PEMBELIAN' ? (
                <>
                  <NumberInput
                    source="current_point"
                    label="Current Point"
                    min="0"
                    style={{ width: '50%', marginRight: 40 }}
                    initialValue={data.current_point}
                    disabled
                  />
                  <NumberInput
                    source="point"
                    label="Point"
                    min="0"
                    style={{ width: '50%' }}
                  />
                </>
              ) : null}
            </div>
            <TextInput source="note" multiline fullWidth />
          </Grid>
          <Grid item sm={6}>
            <Box className={classes.boxDetail}>
              <ImageInput source="photo" label={false} accept="image/*">
                <PreviewImage source="src" />
              </ImageInput>
            </Box>
          </Grid>
          <Grid item sm={12}>
            {data && data.type_id === 'PEMBELIAN' ? (
              <ArrayInput source="details" label="Detail Transaksi">
                <SimpleFormIterator classes={iteratorClasses}>
                  <ReferenceInput
                    source="product_id"
                    reference="master_products"
                    label="Produk"
                    onChange={e => setChangeItems(true)}>
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                  <NumberInput
                    source="qty"
                    label="Qty"
                    className={classes.inputQty}
                    onChange={e => setChangeItems(true)}
                  />

                  <TextInput
                    source="note_detail"
                    label="Keterangan Produk"
                    className={classes.inputNOte}
                  />

                  <FormDataConsumer>
                    {({
                      formData, // The whole form data
                      scopedFormData, // The data for this item of the ArrayInput
                      getSource, // A function to get the valid source inside an ArrayInput
                      ...rest
                    }) => {
                      const handlePrice = e => {
                        scopedFormData.title_price =
                          'Harga ' + e.level_id.replace(/_/gi, ' ');
                        scopedFormData.price =
                          changeItems === false
                            ? parseInt(scopedFormData.price)
                            : e.price_int;
                        scopedFormData.total =
                          changeItems === false
                            ? parseInt(scopedFormData.total)
                            : scopedFormData.qty * e.price_int;
                        scopedFormData.package = e.package;
                        // setProducts(formData.details);
                        SetGrandTotal(
                          formData.details.length == 0
                            ? 0
                            : formData.details.reduce(
                                (prev, next) =>
                                  prev +
                                  (next !== undefined
                                    ? parseInt(next.total)
                                    : 0),
                                0
                              )
                        );

                        const newPoint = Points(formData.details);
                        setPoint(newPoint);
                      };

                      const changePrice = e => {
                        scopedFormData.price = e.target.value;
                        scopedFormData.total =
                          scopedFormData.qty * e.target.value;

                        SetGrandTotal(
                          formData.details.length == 0
                            ? 0
                            : formData.details.reduce(
                                (prev, next) =>
                                  prev +
                                  (next !== undefined
                                    ? parseInt(next.total)
                                    : 0),
                                0
                              )
                        );
                        const newPoint = Points(formData.details);
                        setPoint(newPoint);
                      };

                      const reloadformData = () => {
                        if (formData.details !== undefined) {
                          SetGrandTotal(
                            formData.details.length == 0
                              ? 0
                              : formData.details.reduce(
                                  (prev, next) =>
                                    prev +
                                    (next !== undefined
                                      ? parseInt(next.total)
                                      : 0),
                                  0
                                )
                          );
                          const newPoint = Points(formData.details);
                          setPoint(newPoint);
                        }
                      };

                      return scopedFormData &&
                        scopedFormData.product_id &&
                        scopedFormData.qty ? (
                        <>
                          <Price
                            source={getSource('qty')}
                            scopedata={scopedFormData}
                            idCustomer={formData.customer_id}
                            getPrice={handlePrice}
                          />
                          <Box display="flex">
                            <Box width="50%">
                              <TextField
                                name="price"
                                className={classes.priceCustomer}
                                label={
                                  scopedFormData.title_price
                                    ? scopedFormData.title_price
                                    : 'Harga Produk'
                                }
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={
                                  scopedFormData.price
                                    ? scopedFormData.price
                                    : price
                                }
                                variant="filled"
                                onChange={changePrice}
                              />
                            </Box>
                            <Box width="50%">
                              <Box className={classes.boxPrice}>
                                <Typography className={classes.titleHarga}>
                                  Total
                                </Typography>
                                <Typography className={classes.priceProduct}>
                                  <NumberFormat
                                    number={
                                      scopedFormData.total
                                        ? scopedFormData.total
                                        : price * scopedFormData.qty
                                    }
                                    curency="Rp "
                                  />
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        reloadformData()
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            ) : null}
          </Grid>
          {data && data.type_id === 'PEMBELIAN' ? (
            <>
              <Grid item sm={9}></Grid>
              <Grid item sm={3}>
                <Box className={classes.boxTotal}>
                  <Typography className={classes.titleHarga} align="right">
                    Grand Total
                  </Typography>
                  <Typography className={classes.priceProduct} align="right">
                    {isNaN(parseFloat(grandtotal)) ? (
                      '-'
                    ) : (
                      <NumberFormat number={grandtotal} curency="Rp " />
                    )}
                  </Typography>
                </Box>
                {/* <Box className={classes.boxTotal} style={{ marginTop: '16px' }}>
                  <Typography className={classes.titleHarga} align="right">
                    Points
                  </Typography>
                  <Typography className={classes.priceProduct} align="right">
                    {isNaN(parseFloat(point)) ? (
                      '-'
                    ) : (
                      <NumberFormat number={point} />
                    )}
                  </Typography>
                </Box> */}
              </Grid>
            </>
          ) : null}
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditTransaction;
