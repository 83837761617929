import { Grid } from '@material-ui/core';
import {
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + rp : rp;
};

const ShowPricings = props => {
  return (
    <Show {...props}>
      <Grid container>
        <Grid item sm={12}>
          <HeaderForm
            urlback="/master_pricings"
            titleform="Detail Master Pricing"
          />
        </Grid>
        <Grid item sm={6}>
          <SimpleShowLayout>
            <ReferenceField
              label="Product"
              link={false}
              reference="master_products"
              source="product_id">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="Level Customer"
              link={false}
              reference="master_customer_levels"
              source="level_id">
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              label="Price"
              render={record => (
                <NumberFormat
                  number={record.price.replace('.00', '')}
                  curency="Rp "
                />
              )}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item sm={6}></Grid>
      </Grid>
    </Show>
  );
};

export default ShowPricings;
