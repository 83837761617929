import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import {
  Create,
  DateInput,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import HeaderForm from '../../layout/headerForm';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
  },
  boxImg: {
    padding: theme.spacing(0, 4, 4, 4),
  },
}));

const CreateNews = props => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container className={classes.containerForm}>
          <Grid item sm={12}>
            <HeaderForm urlback="/news" titleform="Form Tambah Berita Baru" />
          </Grid>
          <Grid item sm={6}>
            <TextInput source="title" fullWidth />
            <DateInput source="start_date" label="Start Date" fullWidth />
            <DateInput source="end_date" label="End Date" fullWidth />
            <RichTextInput source="context" label="Description" />
          </Grid>
          <Grid item sm={6}>
            <Box className={classes.boxImg}>
              <ImageInput source="banner" label={false} accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </Box>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateNews;
