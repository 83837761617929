import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Alert from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/styles';
import axios from 'axios';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateInput,
  downloadCSV,
  ExportButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TopToolbar,
  useRefresh,
} from 'react-admin';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import useGrey from '../../assets/icon/usageGrey.svg';

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + (number < 0 ? '-' : '') + rp : rp;
};

const PointFormat = ({ record }) => {
  const theme = useTheme();

  return (
    <Typography
      style={{
        color:
          record.type_id === 'PEMBELIAN'
            ? theme.palette.success.main
            : theme.palette.danger.main,
      }}>
      {record.type_id === 'PEMBELIAN' ? ' ' : '- ' + record.point + ' Points'}
    </Typography>
  );
};

const ActionField = ({ record }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const link =
    record.type_id === 'PEMBELIAN'
      ? '/point_transactions/create'
      : `/point_transactions/${record.id}`;

  return (
    <>
      <Tooltip
        title={record.type_id === 'PEMBELIAN' ? 'Usage Points' : 'Edit Point'}>
        <IconButton
          aria-label="Usage Points"
          onClick={() =>
            history.push(
              link,
              record.type_id === 'PEMBELIAN'
                ? { transaksi_id: record.id }
                : null
            )
          }>
          <img src={useGrey} />
        </IconButton>
      </Tooltip>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(10px) translateY(50px)',
          },
        }}>
        {record.type_id === 'PEMBELIAN' ? (
          <MenuItem
            key="menu-edit"
            onClick={() => history.push(`/point_transactions/${record.id}`)}>
            Edit
          </MenuItem>
        ) : null}

        <MenuItem
          key="menu-detail"
          onClick={() => history.push(`/point_transactions/${record.id}/show`)}>
          Detail
        </MenuItem>
      </Menu>
    </>
  );
};

const exporter = posts => {
  const postsForExport = posts.map(function (a) {
    return {
      customer_name: a.customer_name,
      telephone: a.customer_phone,
      address: a.customer_address,
      transaction_date: a.tgl_trasaction,
      payment: a.type_id === 'TUKAR_POIN' ? a.note : a.product_name,
      Total: a.gross_value,
      Redeem_point:
        a.type_id === 'TUKAR_POIN' ? '- ' + a.point + ' Point' : '-',
      created: a.created,
      created_at: a.created_at,
      updated: a.updated,
      updated_at: a.updated_at,
    };
  });

  jsonExport(
    postsForExport,
    {
      headers: [
        'customer_name',
        'telephone',
        'address',
        'transaction_date',
        'payment',
        'Total',
        'Redeem_point',
        'created',
        'created_at',
        'updated',
        'updated_at',
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'transaction'); // download as 'posts.csv` file
    }
  );
};

const GridFilters = props => (
  <Filter {...props}>
    <DateInput
      source="gte__timestamp"
      style={{ width: '200px' }}
      label="From Transaction Date"
      alwaysOn
    />
    <DateInput
      source="lte__timestamp"
      style={{ width: '200px' }}
      label="To Transaction Date"
      alwaysOn
    />

    <ReferenceInput source="customer_id" reference="customers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ListActions = ({ dataUp }) => {
  const refresh = useRefresh();

  const uploadFile = () => {
    const fileUpload = document.getElementById('uploadFile');

    fileUpload.click();
  };

  const UploadProses = x => {
    const filePath = x.target.value;
    const allowedExtensions = /(\.xls|\.xlsx)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions excel only.');
      x.target.value = '';
      return false;
    } else {
      const param = new FormData();
      param.append('fileUpload', x.target.files[0]);

      axios
        .post(
          process.env.REACT_APP_API_URL +
            '/point_transactions/fn/createFromUpload',
          param,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(response => {
          if (response.data) {
            dataUp(response.data);
            refresh();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <TopToolbar>
      <CreateButton />
      <ExportButton />

      <Button
        onClick={() => uploadFile()}
        label="Upload File"
        style={{ color: '#16A085', fontSize: '14px' }}>
        <CloudUploadIcon style={{ paddingRight: '5px' }} /> Upload
      </Button>

      <input
        type="file"
        accept=".xls, .xlsx"
        style={{ display: 'none' }}
        id="uploadFile"
        onChange={x => UploadProses(x)}
      />
    </TopToolbar>
  );
};

const Aside = ({ infoUp, resetTrans }) => {
  const closeSide = () => {
    resetTrans({});
  };
  return (
    <>
      {infoUp.info ? (
        <div style={{ width: '25%', margin: '1em' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '5px',
              alignItems: 'center',
            }}>
            <Typography variant="h6">Status Upload</Typography>
            <IconButton aria-label="close" onClick={closeSide}>
              <CancelIcon />
            </IconButton>
          </div>
          <div>
            <Typography variant="body2">
              Nama File : {infoUp.nameFile}
            </Typography>
            <Typography variant="body2">
              Tgl/Jam Upload : {infoUp.date}
            </Typography>
          </div>
          <div style={{ marginTop: '15px' }}>
            {infoUp.info &&
              infoUp.info.map(x => (
                <Alert
                  variant="filled"
                  severity={x.status == 'error' ? 'error' : 'success'}
                  style={{ marginBottom: '10px' }}>
                  {x.message}
                </Alert>
              ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const ListTransaction = props => {
  const location = useLocation();

  const [trans, setTrans] = useState({});

  const filterItem =
    location.state && location.state.transaction
      ? {
          eq__type_id: location.state.transaction,
          gte__timestamp: moment().format('YYYY-MM-01'),
          lte__timestamp: moment().add(1, 'days').format('YYYY-MM-DD'),
        }
      : null;

  // const handleLink = e => {
  //   console.log(e);
  // };

  return (
    <List
      {...props}
      sort={{ field: 'timestamp', order: 'DESC' }}
      filter={filterItem}
      filters={<GridFilters date={new Date()} />}
      exporter={exporter}
      aside={<Aside infoUp={trans} resetTrans={x => setTrans(x)} />}
      actions={<ListActions dataUp={x => setTrans(x)} />}>
      <Datagrid>
        <ReferenceField
          label="Customer"
          link={(record, reference) =>
            `/${reference}/${record.customer_id}/show`
          }
          reference="customers"
          source="customer_id">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Date"
          render={record =>
            `${record.tgl_trasaction} ${record.time_trasaction}`
          }
        />
        <FunctionField
          label="Payment"
          render={record =>
            record.type_id === 'PEMBELIAN'
              ? record.product_name
                ? record.product_name.includes('voucher')
                  ? 'Penggunaan ' + record.product_name
                  : 'Pembelian ' + record.product_name
                : record.note
              : !record.note
              ? 'Penukaran Point'
              : record.note
          }
        />
        <FunctionField
          label="Total"
          render={record =>
            record.gross_value ? (
              <NumberFormat number={record.gross_value} curency="Rp " />
            ) : (
              0
            )
          }
        />

        <FunctionField
          label="Redeem Point "
          render={record => <PointFormat record={record} />}
        />

        <FunctionField
          label=""
          render={record => <ActionField record={record} />}
        />
      </Datagrid>
    </List>
  );
};

export default ListTransaction;
