import { createTheme } from '@material-ui/core';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: { main: '#16A085' },
    secondary: { main: '#C64447', dark: '#7B0B0F' },
    background: { default: '#EDF0F8' },
    success: { main: '#29CC97' },
    danger: { main: '#F12B2C' },
    textcolor: { main: '#2B3674' },
    secondarytext: { main: '#707EAE' },
  },
});

export default theme;
